.rll-youtube-player {
    position: relative;
    padding-bottom: 56.23%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
}
.rll-youtube-player iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    background: 0 0;
}
.rll-youtube-player img {
    bottom: 0;
    display: block;
    left: 0;
    margin: auto;
    max-width: 100%;
    width: 100%;
    position: absolute;
    right: 0;
    top: 0;
    border: none;
    height: auto;
    cursor: pointer;
    -webkit-transition: 0.4s all;
    -moz-transition: 0.4s all;
    transition: 0.4s all;
}
.rll-youtube-player img:hover {
    -webkit-filter: brightness(75%);
}
.rll-youtube-player .video__play {
    height: 72px;
    width: 72px;
    left: 50%;
    top: 50%;
    margin-left: -36px;
    margin-top: -36px;
    position: absolute;
    background: url(https://4bfejp2jq0q2273jfp3mhqaw-wpengine.netdna-ssl.com/wp-content/plugins/wp-rocket/assets/img/youtube.png) no-repeat;
    cursor: pointer;
}

.video__iframe {
    &.hide {
        display: none;
    }
}
