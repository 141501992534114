.contactUs {
    color: $color-white !important;
    display: block !important;
    padding: 0 !important;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
        color: $color-white;
    }

    h2 {
        font-weight: 700;
        font-size: 32px;
    }

    .mod {
        position: relative;

        &::after {
            position: absolute;
            top: 50%;
            right: 10px;
            margin-top: 3px;
            border: 6px solid;
            border-color: #fff transparent transparent transparent;
            content: "";
            transform: translateY(-50%);
            pointer-events: none;
        }
    }

    .form {
        label {
            display: none;
        }

        input,
        select,
        textarea {
            background-color: rgba(255, 255, 255, 0.27) !important;
            border-radius: 10px !important;
            color: $color-white !important;
            padding: 16px !important;
            border: none !important;

            &::placeholder {
                color: $color-white;
            }
        }

        button,
        input[type="submit"],
        input[type="clear"] {
            background: $color-white !important;
            color: #08668e !important;
            padding: 10px 30px !important;
            border: none !important;
            transition: all ease-in-out 0.3s;
            font-size: 18px;
            font-family: "Open Sans", Helvetica, Arial, Lucida, sans-serif;
            line-height: 1.7em !important;

            &:hover {
                background-color: #2c96e8 !important;
                border: none !important;
                letter-spacing: 1px;
            }
        }

        select {
            font-size: 14px;
            appearance: none;

            &:focus,
            &:active,
            option {
                color: $color-grey-33;
            }
        }

        .text {
            h1 {
                font-weight: 700;
                font-size: 30px;
                line-height: 1.3em;
                padding-bottom: 10px;
                color: #fff;
            }

            p {
                line-height: 1.5em;
                font-size: 18px;
            }
        }

        .text.success {
            margin-top: 60px;
            font-style: italic;
            background: rgba(255, 255, 255, 0.2);
            border-radius: 10px;
            height: 200px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        .text.error {
            display: none;
        }

        .form-row {
            margin-bottom: 3%;

            .g-recaptcha {
                display: flex;
                justify-content: flex-end;
                padding-top: 15px;

                @media screen and (max-width: 420px) {
                    display: block;

                    > div {
                        transform: scale(0.85);
                        transform-origin: 0;
                        filter: progid:DXImageTransform.Microsoft.Matrix(M11=0.77,M12=0,M21=0,M22=0.77,SizingMethod='auto expand');
                    }
                }
            }
        }

        .form-item-l,
        .form-item-m,
        .form-item-s {
            display: inline-block;
            width: 47.9%;

            &:nth-of-type(2n) {
                margin-left: 3%;
            }

            @media screen and (max-width: $width-small-tablet-max) {
                width: 100%;

                &:nth-of-type(2n) {
                    margin-left: 0;
                    margin-top: 3%;
                }
            }
        }

        .button-wrapper {
            position: relative;
            text-align: right;

            input[type="submit"] {
                display: inline-block;
                margin-left: 15px;
            }

            @media screen and (max-width: 420px) {
                text-align: left;

                input[type="submit"] {
                    margin-left: 0;
                }
            }
        }

        .button-wrapper--loading {
            input[type="submit"] {
                color: transparent !important;
            }

            &:before {
                right: 50px;
                left: unset;
                border: 2px solid red;
                border-top-color: transparent;
                border-left-color: transparent;
                border-bottom-color: #08668e;
                border-right-color: #08668e;
                z-index: 1;
            }
        }
    }

    .et_pb_contact_right {
        color: $color-white !important;
        font-weight: 700 !important;
    }

    .et_pb_section {
        background: transparent;
        background-size: cover;
    }

    .contactUs__column {
        padding-top: 45px;
    }
}

.homepage {
    .contactUs__column {
        &:first-child {
            @media screen and (max-width: $width-small-tablet-max) {
                display: none;
            }
        }
    }
}

.scanDetail {
    .contactUs {
        .et_pb_section {
            padding: 0;
        }

        .et_pb_column_9 {
            padding-bottom: 40px;
        }

        .form {
            padding: 0;
            background: transparent;
        }
    }
}
