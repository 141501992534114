@import "../variables.scss";

.slideshow {
  position: relative;
  margin-top: -10px;
  color: $color-white;
  overflow: hidden;
}

.slideshow__list {
  position: relative;
  min-height: 430px;
  background: #f1f1f1;

  @media screen and (max-width: $width-tablet-max) {
    height: 700px;
  }

  @media screen and (max-width: $width-small-tablet-max) {
    height: 500px;
  }
}

.slideshow__item {
  width: 100%;
  height: 100%;
  background: #f1f1f1;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  transition: all ease-in-out 0.5s;

  &--active {
    display: block;
    animation: slideshow ease-in-out 0.5s;
    animation-play-state: running;
  }

  @keyframes slideshow {
    from {
      opacity: 0.5;
    } to {
      opacity: 1;
    }
  }
}

.slideshow__container {
  position: relative;
  width: 88%;
  height: 100%;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.slideshow__details {
  width: 47%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  animation: slideup ease-in-out 0.5s;
  animation-play-state: running;

  &--left {
    left: 0%;
  }

  &--right {
    right: 0%;
  }

  &--center {
    transform: translate(-50%, -50%);
    left: 50%;
  }

  &--widen {
    width: 80%;
  }

  h1, h2 {
    color: $color-white;
  }

  h1 {
    font-size: 40px;
    color: $color-white;
    line-height: 1.3em;
  }

  &--lightBackground {
    background: $color-white-transparent-90;
    color: #002a51;
    font-size: 17px;
    line-height: 1.7em;
    padding: 30px;

    h2 {
      font-size: 35px;
      color: #002a51;
      font-weight: 700;
    }
  }

  @media screen and (max-width: $width-small-tablet-max) {
    width: 100%;
  }

  @keyframes slideup {
    from {
      margin-top: 100px;
    }
    to {
      margin-top: 0;
    }
  }
}

.slideshow__imageHolder {
  position: absolute;
  height: 80%;
  width: 47%;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;

  &--left {
    left: 0%;
  }

  &--right {
    right: 0%;
  }

  @media screen and (max-width: $width-tablet-max) {
    height: 45%;
  }

  @media screen and (max-width: $width-small-tablet-max) {
    display: none;
  }
}

.slideshow__image {
  height: 100%;

  @media screen and (max-width: $width-standard-screen-max) {
    height: auto;
  }
}

.slideshow__controlsItem {
  display: inline-block;
  width: 7px;
  height: 7px;
  border-radius: 100%;
  background: $color-white-transparent-50;
  cursor: pointer;

  &--active {
    background: $color-white;
  }

  &:not(:last-child) {
    margin-right: 10px;
  }
}

.slideshow__controls {
  position: absolute;
  bottom: 20px;
  text-align: center;
  width: 100%;
  z-index: 2;

  &--dark {
    .slideshow__controlsItem {
      background: $color-black-transparent-30;

      &--active {
        background: $color-grey-33;
      }
    }
  }
}

.slideshow__buttonContainer {
  margin-top: 30px;

  &--center {
    text-align: center;
  }
}

.slideshow__button {
  padding: 0.5em 1em;
  line-height: 1.7em;
  font-size: 19px;
  border-radius: 8px;
  font-family: 'Open Sans',Helvetica,Arial,Lucida,sans-serif;
  transition: all ease-in-out 0.3s;
  font-weight: 700;
  color: $color-white;

  &--whiteTransparent {
    border: 3px solid $color-white;
    background: $color-white-transparent-15;

    &:hover {
      background: $color-white-transparent-20;
    }
  }

  &--orange {
    background: #f2982b;
    font-size: 23px;

    &:hover {
      background: #dd7d00;
    }
  }

  &:hover {
    color: $color-white;
  }
}

.slideshow__loading,
.slideshow__noItem {
  color: $color-grey-cc;
  font-size: 25px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.slideshow__loading {
  font-size: 15px;
  color: $color-grey-33;
  z-index: 1;
}
