/* Fonts */

/* Colors */
$color-black: #000;
$color-grey-3c: #3c3c3c;
$color-light-gray: #f9f9f9;

/* Colors */
/* brand colors */
$color-jabil-dark-blue: #003865;
$color-jabil-darker-blue: #002b49;
$color-jabil-green: #008651;
$color-jabil-light-green: #7fba00;
$color-jabil-light-blue: #15bef0;
$color-jabil-purple: #702f8a;
$color-jabil-orange: #dc582a;
$color-jabil-red: #cb333b;
$color-jabil-grey: #60605b;
$color-jabil-light-grey: #cccccc;
$color-jabil-light-grey-be: #bebebe;

// Random colors???
$color-jabil-blue: #1867b3;
$color-jabil-deep-blue: #0c345a;
$color-table-odd: #dae3f3;
$color-blue-18-transparent-15: rgba(24, 103, 179, 0.15);
$color-blue-transparent-50: rgba(0, 56, 101, 0.5);
$color-blue-transparent-75: rgba(0, 56, 101, 0.75);
$color-blue-callout-overlay: rgba(12, 52, 90, 0.5);

// Blacks
$color-black-02: #020202;
$color-black-07: #070707;
$color-black-transparent-16: rgba(0, 0, 0, 0.16);
$color-black-transparent-25: rgba(0, 0, 0, 0.25);
$color-black-transparent-30: rgba(0, 0, 0, 0.3);
$color-black-transparent-40: rgba(0, 0, 0, 0.4);
$color-black-transparent-50: rgba(0, 0, 0, 0.5);
$color-black-transparent-70: rgba(0, 0, 0, 0.7);
$color-black-transparent-75: rgba(0, 0, 0, 0.75);
$color-black-transparent-85: rgba(0, 0, 0, 0.85);
$color-black-transparent-90: rgba(0, 0, 0, 0.9);

// Greys
$color-grey-26: #262626;
$color-grey-33: #333;
$color-grey-3c: #3c3c3c;
$color-grey-4a: #4a4a4a;
$color-grey-66: #666;
$color-grey-70: #707070;
$color-grey-76: #767676;
$color-grey-77: #777;
$color-grey-9c: #9c9c9c;
$color-grey-ac: #acacac;
$color-grey-ad: #adadad;
$color-grey-b1: #b1b1b1;
$color-grey-cc: #ccc;
$color-grey-dd: #ddd;
$color-grey-de: #dedede;
$color-grey-f0: #f0f0f0;
$color-grey-60: #606060;

// Whites
$color-white: #fff;
$color-white-95: rgba($color-white, 0.95);
$color-white-fa: #fafafa;
$color-white-off: #d2d2d2;
$color-white-transparent-15: rgba(255, 255, 255, 0.15);
$color-white-transparent-20: rgba(255, 255, 255, 0.2);
$color-white-transparent-40: rgba(255, 255, 255, 0.4);
$color-white-transparent-50: rgba(255, 255, 255, 0.5);
$color-white-transparent-75: rgba(255, 255, 255, 0.75);
$color-white-transparent-80: rgba(255, 255, 255, 0.8);
$color-white-transparent-90: rgba(255, 255, 255, 0.9);

/* Color Pops
    These should match the values in 
    light-modules/jabil-main/dialogs/includes/jabil-one/fields/colorSelect.yaml
*/

$colorPops: (
    "red": $color-jabil-red,
    "blue": $color-jabil-blue,
    "black": $color-black,
    "green": $color-jabil-green,
    "lite-green": $color-jabil-light-green,
    "grey": $color-jabil-grey,
    "light-grey": $color-grey-ad,
    // Orange is special for healthcare so mixes blue and orange
        "orange": $color-jabil-orange,
    "purple": $color-jabil-purple,
    "transparentBlack": $color-black-transparent-75,
    "dark-blue": $color-jabil-dark-blue,
    "darkBlue": $color-jabil-dark-blue,
    "none": $color-jabil-dark-blue,
    "light-blue": $color-jabil-light-blue,
    "white": $color-white
);

/* Min Sizes */
$width-tablet-min: 500px;
$width-medium-tablet-min: 768px;
$width-large-tablet-min: 900px;
$width-desktop-min: 1024px;
$width-standard-screen-min: 1366px;
$width-medium-screen-min: 1600px;
$width-large-screen-min: 1920px;

/* Max Sizes */
$width-mobile-max: $width-tablet-min - 1;
$width-small-tablet-max: $width-medium-tablet-min - 1;
$width-tablet-max: $width-desktop-min - 1;
$width-standard-screen-max: $width-standard-screen-min - 1;
$width-medium-screen-max: $width-medium-screen-min - 1;
$width-desktop-max: $width-large-screen-min - 1;

/* Default padding */
$buttonPad: 8px 45px;
$containerPad: 20px;
