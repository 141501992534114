@import "../variables.scss";
@import "../modules/slideshow.scss";
@import "../modules/iconSlideshow.scss";
@import "../modules/rllYoutubePlayer.scss";
@import "../modules/mgnlForm.scss";
@import "../modules/contactUs.scss";
@import "../modules/video.scss";

h1.home {
    width: 100%;
    text-align: center;
    padding-top: 40px;
    padding-bottom: 0;
}

h2.home {
    width: 100%;
    text-align: center;
    padding-top: 40px;
    padding-bottom: 0;
}
