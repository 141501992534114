@import "../variables.scss";

.iconSlideshow {
    min-height: 280px;
    position: relative;
    padding: 0;
}

.iconSlideshow__loading,
.iconSlideshow__noItem {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.iconSlideshow__noItem {
    font-size: 20px;
}

.iconSlideshow__list {
    padding: 27px 0;
    max-width: 1080px;
    width: 80%;
    margin: auto;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    position: relative;
    display: none;

    &--wrap {
        white-space: normal;
    }
}

.iconSlideshow__tooltip {
    background: $color-black-transparent-50;
    padding: 0 10px;
    border-radius: 5px;
    color: $color-white;
    position: absolute;
    top: -40px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 12px;
    animation: showTooltip ease-in-out 0.5s;
    display: none;

    &::after {
        position: absolute;
        bottom: -10px;
        left: 50%;
        transform: translateX(-50%);
        content: "";
        border-top: 5px solid $color-black-transparent-50;
        border-bottom: 5px solid transparent;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
    }

    @keyframes showTooltip {
        from {
            top: -20px;
            opacity: 0;
        }
        to {
            top: -40px;
            opacity: 1;
        }
    }
}

.iconSlideshow__item {
    background: $color-white;
    margin: 15px;
    position: relative;
    transition: all ease-in-out 0.3s;
    display: inline-block;
    animation: showItem ease-in-out 1s;

    &:hover {
        box-shadow: #dcdcdc 0 0 10px 2px;

        & > .iconSlideshow__tooltip {
            display: block;
        }
    }

    @keyframes showItem {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
}

.iconSlideshow__link {
    width: 240px;
    height: 192px;
    display: inline-block;

    @media screen and (min-width: $width-medium-tablet-min) {
        width: 123px;
        height: 98px;
    }

    @media screen and (min-width: $width-desktop-min) {
        width: 174px;
        height: 139px;
    }

    @media screen and (min-width: $width-standard-screen-min) {
        width: 240px;
        height: 192px;
    }
}

.iconSlideshow__img {
    max-width: 70%;
    max-height: 70%;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.iconSlideshow__icon {
    position: absolute;
    width: 27px;
    height: 27px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    opacity: 0;
    transition: all ease-in-out 0.3s;
    z-index: 1;

    &--show {
        opacity: 1;
    }

    &--prev {
        left: 0;
        background: url(../../src/img/icons/prev.png) $color-white;

        &:hover {
            background: url(../../src/img/icons/prev.png) $color-white -27px 0;
        }
    }

    &--next {
        right: 0;
        background: url(../../src/img/icons/next.png) $color-white;

        &:hover {
            background: url(../../src/img/icons/next.png) $color-white 27px 0;
        }
    }

    @media screen and (max-width: $width-standard-screen-max) {
        opacity: 1;
    }
}
