.form {
    .text.error,
    #formErrorsDisplay {
        color: #b94a48;
        margin-top: 20px;
        margin-bottom: 30px;

        h1 {
            color: #b94a48;
            font-style: italic;
        }
    }

    #mgnlhp {
        display: none;
    }

    fieldset {
        width: 100%;
        min-width: 0;
        display: table-cell;
    }
}

.form-row {
    grid-column: span 12 / span 12;
    margin-bottom: 20px;
    width: auto;
    clear: both;

    > * {
        grid-column: span 12 / span 12;
    }

    label {
        font-family: "Open Sans", Helvetica, Arial, Lucida, sans-serif;
        font-size: 14px;
        color: #444444;
        font-weight: bold;
        text-align: left;
        margin: 0;
        padding: 0 0 3px 0;
        width: auto;
        display: block;
        float: none;
        max-width: 100%;
    }

    dfn {
        word-wrap: break-word;
        color: #b94a48;
        font-weight: bold;
    }

    input[type="text"],
    input[type="password"],
    input[type="email"],
    input[type="number"],
    input[type="url"],
    input[type="tel"],
    input[type="date"],
    input[type="phone"],
    input[type="search"],
    select,
    textarea {
        color: #555555;
        background-color: #ffffff;
        border-color: #cccccc;
        border-width: 1px;
        border-style: solid;
        -moz-border-radius: 4px;
        -webkit-border-radius: 4px;
        border-radius: 4px;
        width: 100%;
        max-width: 100%;
        font-size: 14px;
        padding: 6px 10px;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        outline: none;
        font-weight: normal;
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset;
        font-family: "Open Sans", Helvetica, Arial, Lucida, sans-serif;
        margin-bottom: 0;
    }

    select {
        option {
            line-height: 1.3;
        }
    }

    textarea {
        vertical-align: top;
        height: auto;
    }

    span {
        grid-column: span 12 / span 12;
        font-size: 0.75rem;
        width: auto;
        display: block;
        float: none;
    }

    .form-item {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        input[type="checkbox"] {
            margin-right: 10px;
        }

        label {
            font-weight: normal;
        }
    }

    @media screen and (max-width: 420px) {
        .recaptcha {
            display: block;

            > div {
                transform: scale(0.85);
                transform-origin: 0;
                filter: progid:DXImageTransform.Microsoft.Matrix(M11=0.77,M12=0,M21=0,M22=0.77,SizingMethod='auto expand');
            }
        }
    }
}

.button-wrapper {
    input[type="submit"] {
        width: auto;
        font-family: "Lucida Grande", "Lucida Sans Unicode", Tahoma, sans-serif;
        font-size: 14px;
        height: auto;
        line-height: normal;
        text-align: center;
        background: #ffffff;
        border-width: 1px;
        border-color: #cccccc;
        border-style: solid;
        color: #444444;
        cursor: pointer;
        font-weight: normal;
        -moz-border-radius: 4px;
        -webkit-border-radius: 4px;
        border-radius: 4px;
        text-shadow: none;
        padding: 6px 11px;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        -ms-box-sizing: border-box;
        -moz-box-shadow: 0 1px 1px #eeeeee;
        -webkit-box-shadow: 0 1px 1px #eeeeee;
        box-shadow: 0 1px 1px #eeeeee;
        margin: 10px;
        margin-left: 0;
        margin-right: 0;
        vertical-align: middle;
        clear: both;
    }
}

.button-wrapper--loading {
    position: relative;

    input[type="submit"] {
        background: #fff;
        opacity: 0.8;
        color: transparent !important;
        text-shadow: none !important;
    }

    &:before {
        content: "";
        display: inline-block;
        position: absolute;
        background: transparent;
        border: 2px solid #fff;
        border-top-color: transparent;
        border-left-color: transparent;
        border-bottom-color: #444444;
        border-right-color: #444444;
        border-radius: 50%;
        box-sizing: border-box;
        top: 50%;
        left: 25px;
        margin-top: -10px;
        margin-left: 0;
        width: 20px;
        height: 20px;
        -webkit-animation: spin 2s linear infinite;
        -moz-animation: spin 2s linear infinite;
        -o-animation: spin 2s linear infinite;
        animation: spin 2s linear infinite;
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
